.gm-style div div div div div div div div {
  /* background: #0d3693;
  );
  padding: 5px;
  word-break: break-all;
}

.gm-style-iw-d {
  overflow: hidden !important;
  /* background: rgb(98 138 190) 6%; */
}

.gm-style .gm-style-iw-t::after {
  background: #517db7;
}

.gm-ui-hover-effect img {
  background: white;
  border-radius: 10px;
}

/* ::-webkit-scrollbar {
  display: none;
} */
